import { useEffect, useState } from "react";
import * as apiService from "../api/apiService";
import { useAuth } from "../context/authcontext";

export const useGetEventModes = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [eventModes, setEventModes] = useState({ Options: [] })
    const [refresh, setRefresh] = useState(false)
    const {user} = useAuth()

    
    useEffect(() => {
        setIsLoading(true)
        apiService.getEventModes(user.Event.Id).then((items) => {
            setEventModes(items[0])
            setIsLoading(false)
        })
    }, [refresh])

    const refreshItems = () => {
        setRefresh(!refresh)
    }

    return {eventModes, refreshItems, isLoading}

}

export const useGetControlPoints = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [controlPoints, setControlPoints] = useState([])
    const [refresh, setRefresh] = useState(false)
    const {user} = useAuth()

    useEffect(() => {
        setIsLoading(true)
        apiService.getControlPoints(user.Event.Id).then((items) => {
            setControlPoints(items.ControlPoints)
            setIsLoading(false)
        })
    }, [refresh])

    const refreshControlPoints = () => {
        setRefresh(!refresh)
    }

    return {controlPoints, refreshControlPoints, isLoading}
}