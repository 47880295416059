import React, { useEffect, useState } from 'react'

import { Grid, Typography, Container, Input, Button, RadioGroup, Radio, FormControl, FormLabel, FormControlLabel, Box, Select, InputLabel, OutlinedInput, MenuItem, Checkbox, ListItemText } from '@mui/material'
import { useAuth } from '../context/authcontext'
import SquareRadioButtons from '../components/SquareRadioButtons'
import { useGetEventModes, useGetControlPoints } from '../hooks/events'
import { ScanModes } from '../utils/scanModeEnum'
import { useScan } from '../context/scanContext'
import { useNavigate } from 'react-router-dom'
import { AppLayout } from '../components/AppLayout'

const EventModeView = () => {

  const { scanMode, setScanMode, controlPoint, setControlPoint, products, setProducts, saveScanConfig, isConfigured } = useScan()
  const { eventModes, refreshModes, isLoading } = useGetEventModes()
  const { controlPoints, refreshControlPoints, isloading } = useGetControlPoints()
  const { user } = useAuth()

  const navigate = useNavigate()

  const [error, setError] = useState()

  useEffect(() => {
    if (!user.Admin && isConfigured) {
      navigate('/scan')
    }
  }, [])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (typeof value === 'string') {
      setProducts(value.split(','));
    } else {
      // Créer un nouveau tableau basé sur les options sélectionnées
      const selectedOptions = value.map(val => {
        return eventModes.Options.find(option => JSON.parse(option.Name).fr === val);
      });
      setProducts(selectedOptions);
    }
  };

  const safelyParseJSON = (json) => {
    try {
      return JSON.parse(json);
    } catch (e) {
      return null;
    }
  };

  const handleSelect = () => {
    setError("")
    if (scanMode === ScanModes.PRODUCT && products.length === 0) {
      setError("Veuillez sélectionner au moins un produit")
      return
    }

    if (scanMode === ScanModes.ACCESS && !controlPoint) {
      setError("Veuillez sélectionner au moins un point de contrôle")
      return
    }

    if (saveScanConfig()) {
      navigate("/scan")
    }
  }

  return (

    //<Container maxWidth="sm" style={{ height: '100vh', display:'flex', alignItems: 'center', justifyContent: 'center' }}>
    <AppLayout>
      <Box textAlign='center' alignContent='center'>
        <Typography variant='h4'>Sélection du mode</Typography>
        <Typography variant='h5'>{controlPoints.Name}</Typography>
        <Grid>
          <Grid py={2}>
            <SquareRadioButtons valueLeft={ScanModes.ACCESS} valueRight={ScanModes.PRODUCT} valueRightLabel="Consommation de produits" valueLeftLabel="Contrôle d'accès" onChange={setScanMode} value={scanMode} />
          </Grid>
          <Grid py={2}>
            {scanMode === ScanModes.ACCESS ?
              <FormControl fullWidth>
                <InputLabel>Sélection du point de contrôle</InputLabel>
                <Select
                  label="Sélection du point de contrôle"
                  value={controlPoint}
                  onChange={(selection) => {
                    var value = selection.target.value
                    setControlPoint(selection.target.value)
                    setProducts(value.Products)
                  }
                  }
                >
                  {controlPoints.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option.Name}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
              : scanMode === ScanModes.PRODUCT ?
                <FormControl fullWidth>
                  <InputLabel>Sélection des produits</InputLabel>
                  <Select
                    multiple
                    value={products.map(p => JSON.parse(p.Name).fr) || []}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.length > 1 ? selected.length + " produits sélectionnés" : selected}
                    label="Sélection des produits">
                    {
                      eventModes.Options.map((option) => {
                        const optionName = JSON.parse(option.Name).fr
                        return (
                          <MenuItem key={option.Id} value={optionName}>
                            <Checkbox checked={products.some(product => product.Id === option.Id)} />
                            <ListItemText primary={optionName} />
                          </MenuItem>
                        )
                      }
                      )
                    }
                  </Select>
                </FormControl> : <></>}
          </Grid>
          <Grid>
            <Typography color="error">{error}</Typography>
          </Grid>

        </Grid>
        <Grid container position='fixed' bottom='0' left='0' justifyContent='center' p={3}>
          <Grid item xs={12} lg={6}>
            <Button fullWidth variant='contained' color='success' size='large' onClick={handleSelect}>Valider la configuration</Button>
          </Grid>
        </Grid>
      </Box>
    </AppLayout>
  )
}

export default EventModeView