import React, { useEffect } from 'react'
import { useAuth } from '../context/authcontext'
import { Navigate, Outlet, Route, redirect, useNavigate } from 'react-router-dom'



const PrivateRoute = ({children}) => {
    const {user} = useAuth()
    const {navigate} = useNavigate()

    if(!user) {
        return <Navigate to="/login" replace/>
    }


  return <Outlet/>
}

export default PrivateRoute