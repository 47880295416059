import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button, TableContainer, TableHead, TableBody, TableCell, TableRow, Table } from '@mui/material';
import ErrorTwoTone from '@mui/icons-material/ErrorTwoTone';
import ValidTwoTone from '@mui/icons-material/CheckCircleTwoTone';
import ProductSelection from '../components/ProductTicketScanResponse';
import { ScanModes } from '../utils/scanModeEnum';
import TicketHistory from '../components/TicketHistory';
import { useScan } from '../context/scanContext';
import { useTicketHistory } from '../hooks/useTicketHistory';
import ErrorScanResponse from '../components/ErrorScanResponse';
import ProductTicketScanResponse from '../components/ProductTicketScanResponse';
import AccessTicketScanResponse from '../components/AccessTicketScanResponse';

const ScanResponse = ({ error, validationResponse, onCancel, onProductValidation, isHistoryValidation }) => {

  const [showHistory, setShowHistory] = useState(false)
  const {currentCode, scanMode} = useScan()
  // const { getTicketHistory, scanEvents } = useTicketHistory()


  if (isHistoryValidation) {
    return <TicketHistory onCancel={onCancel} />
  }

  if (scanMode === ScanModes.ACCESS) {
    return <AccessTicketScanResponse onCancel={onCancel} />
  }

  if (scanMode === ScanModes.PRODUCT) {
    return <ProductTicketScanResponse onCancel={onCancel}/>
  }

  // const showTicketHistory = () => (
  //   getTicketHistory(currentCode, () => {
  //     setShowHistory(true)
  //   }));
  
    
  // if (error) {
  //   return <ErrorScanResponse message={error} onCancel={onCancel}/>;
  // }

  // if (isHistoryValidation) {
  //   return <TicketHistory onCancel={onCancel} scanEvents={validationResponse}/>
  // }

  // if (scanMode === ScanModes.ACCESS) {
  //  return <AccessTicketScanResponse validationResponse={validationResponse} onCancel={onCancel} showHistory={null}/>
  // }

  // return <ProductTicketScanResponse onCancel={onCancel} onValidation={onProductValidation}/>;
};

export default ScanResponse;