import React, { useEffect, useState } from 'react'
import { Box, Typography, Grid, Button, TableContainer, TableHead, TableBody, TableCell, TableRow, Table, CircularProgress } from '@mui/material';
import ErrorTwoTone from '@mui/icons-material/ErrorTwoTone';
import ValidTwoTone from '@mui/icons-material/CheckCircleTwoTone';
import WarningTwoTone from '@mui/icons-material/WarningTwoTone';
import { useTicketValidation } from '../hooks/useTicketValidation';
import { useScan } from '../context/scanContext';
import TicketHistory from './TicketHistory';
import { useTicketHistory } from '../hooks/useTicketHistory';
import { useProductValidation } from '../hooks/useProductValidation';
import ProductTicketScanResponse from './ProductTicketScanResponse';
import ProductsSelectionTable from './ProductsSelectionTable';
import SquareRadioButtons from './SquareRadioButtons';

const AccessTicketScanResponse = ({ onCancel, showTicketHistory }) => {

  const { validateTicket, validationResponse } = useTicketValidation()
  const { validateProduct, consumeProducts, availableProductList } = useProductValidation()
  const { getTicketHistory, scanEvents } = useTicketHistory()
  const { currentCode, controlPoint, productList } = useScan()
  const [isLoading, setIsLoading] = useState(true)
  const [showHistory, setShowHistory] = useState(false)
  const [productConsumed, setProductConsumed] = useState(false)
  const [isZoneChange, setIsZoneChange] = useState(true)


  useEffect(() => {

    if (controlPoint.HasProductsToValidate) {
      validateProduct(currentCode,checkAvailableProducts, () => handleValidateTicket())
    } else {
      validateTicket(currentCode, () => setIsLoading(false))
    }

  }, [])


  const handleShowHistory = () => {
    setIsLoading(true)
    getTicketHistory(currentCode, () => {
      setShowHistory(true)
      setIsLoading(false)
    })
  }

const checkAvailableProducts = (p) => {
  if(p.length === 0)
  {
    validateTicket(currentCode, () => setIsLoading(false))
  }else {
    setIsLoading(false)
  }
}


  const handleConsumeProduct = () => {
    setIsLoading(true)
    consumeProducts()
    setProductConsumed(true)
    validateTicket(currentCode, () => setIsLoading(false))

  }

  const handleValidateTicket = () => {
    setProductConsumed(true)
    validateTicket(currentCode, () => setIsLoading(false))
  }

  // const handleValidateZoneChange = (value) => {
  //   console.log(value)
  // }

  if (isLoading) {
    return (
      <Box container textAlign='center' alignContent="center" height="100%" direction="column" bgcolor="#ffffff" borderRadius={5} p={2}>
        <CircularProgress />
        <Typography>Chargement en cours</Typography>
      </Box>
    )
  }

  // if (controlPoint.Type === "ZoneChange") {
  //   return (
  //     <Grid container textAlign='center' direction='column' bgcolor="#ffffff" borderRadius={5} p={2}>
  //       <Typography variant="h5" mb={2}>Sélectionner la zone</Typography>
  //       <SquareRadioButtons valueLeft={controlPoint.InboundZone} valueLeftLabel={controlPoint.InboundZone} valueRight={controlPoint.OutboundZone} valueRightLabel={controlPoint.OutboundZone} onChange={handleValidateZoneChange}/>
  //     </Grid>
  //   )
  // }

  if (showHistory) {
    return (<TicketHistory onCancel={() => setShowHistory(false)} scanEvents={scanEvents} />)
  }




  return (<>
    {productList.length === 0 || productConsumed ?
      <Grid container textAlign='center' direction='column' bgcolor="#ffffff" borderRadius={5} p={2}>
        <Typography variant='h2' fontWeight="bold">{validationResponse?.isValid ? "Valide" : "Invalide"}</Typography>
        <Grid item>
          {validationResponse.isValid ?
            <ValidTwoTone sx={{ fontSize: 200, color: "#00dd00" }} /> :
            <ErrorTwoTone sx={{ fontSize: 200, color: "#ff0000" }} />
          }
        </Grid>
        <Typography variant='h4' fontWeight="bold">{validationResponse.message}</Typography>
        <Typography pt={5}>Informations du billet</Typography>
        <Grid item width='100%' px={2} pb={2}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Type de billet</TableCell>
                  <TableCell align='center'>{validationResponse.ticketName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell># de billet</TableCell>
                  <TableCell align='center'>{validationResponse.ticketNumber}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid container position='fixed' bottom='0' left='0' justifyContent='center' p={3}>
          <Grid container pb={1}>
            <Button fullWidth variant='contained' size='large' onClick={handleShowHistory}>Consulter l'historique</Button>
          </Grid>
          <Button fullWidth variant='contained' size='large' onClick={onCancel}>Retour</Button>
        </Grid>
      </Grid>
      :

      <Grid container textAlign='center' direction='column' bgcolor="#ffffff" borderRadius={5} p={2}>
        {/* <Typography variant='h2' fontWeight="bold">Produit</Typography> */}
        <Grid item>
          <WarningTwoTone sx={{ fontSize: 150, color: "#ffbb00" }} />
        </Grid>
        <Typography variant='h4' fontWeight="bold">Produits à remettre pour ce billet</Typography>
        <ProductsSelectionTable />
        <Grid container position='fixed' bottom='0' left='0' justifyContent='center' py={3}>
          <Grid container justifyContent='space-between' maxWidth='sm' spacing={2} px={1}>
            <Grid item xs={6} lg={3}>
              <Button fullWidth variant='contained' color='error' size='large' onClick={onCancel}>Annuler</Button>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Button fullWidth variant='contained' color='success' size='large' onClick={() => handleConsumeProduct()}>Valider</Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

    }
  </>
  )
}

export default AccessTicketScanResponse