import { useState } from "react"
import { getTicketScanEvents } from "../api/apiService"
import { useAuth } from "../context/authcontext"

export const useTicketHistory = () => {

    const {user} = useAuth()

    const ticketHistoryStub = [
        {
            "EventTime": "2024-06-17T22:13:18.85642",
            "EventMode": "Product",
            "EventType": "ProduitConsomme",
            "Description": "{\"fr\":\"Bracelet VIP\"}",
            "Quantity": 5,
            "Source": "Utilisateur",
            "Result": "Invalid",
            "TicketMode": "Product",
            "UserProfileId": 1,
            "ScanTicketItemId": 3,
            "ScanTicketId": 3,
            "ScanTicket": {
                "Code": "FHDFH240617215534F6E8Z8WW3600003",
                "SerialNumber": "600003",
                "PublicId": "f5f4102a-c798-41cb-91bb-8e1000387555",
                "Disabled": false,
                "Name": "{\"fr\":\"Bracelet VIP\"}",
                "IsDailyTicket": false,
                "OrderNumber": "FESTIVENT24-ADMINGEN",
                "EventCode": "FESTIVENT24",
                "ConfirmationNumber": "ADMINGEN",
                "EventId": 1,
                "TicketConfigurationId": 2,
                "TicketBatchId": 3,
                "Id": 3,
                "CreatedBy": "main.admin@cyprex.com"
            },
            "EventId": 1,
            "Id": 1,
            "CreatedBy": "admin@cyprex.com"
        }
    ]

    const [scanEvents, setScanEvents] = useState(ticketHistoryStub)
    const [isLoading, setIsLoading] = useState(false)

    

    const getTicketHistory = (code, onValidationComplete, onValidationError) => {
        setIsLoading(true)

        getTicketScanEvents(user.Event.Id, code)
        .then((items) => {
            try {
                if (!Array.isArray(items)){
                    throw Error
                }
            setScanEvents(items)
            if (onValidationComplete){
                onValidationComplete()
            }
        } catch {
            if (onValidationError) {
                onValidationError(items.Message)
            }
        }
    })
        setIsLoading(false)
    }

    return {getTicketHistory, scanEvents, isLoading}

}