import { useEffect, useState } from "react"
import { postAccessScan, getAvailableProducts, postConsumeProducts } from "../api/apiService"
import { useAuth } from "../context/authcontext"
import { useScan } from "../context/scanContext"
import { ScanModes } from "../utils/scanModeEnum"

export const useProductValidation = () => {
    const availableProducts = [
        {
            Id: 11,
            ItemName: "{\"fr\":\"10 dégustations\"}",
            Number: "600007",
            OriginalQuantity: 10,
            ProductId: 3,
            Quantity: 10,
            TicketName: "{\"fr\":\"10 dégustations\"}",
            QuantityToConsume: 0,
        }
    ]

    const productsToConsume = [
        {
            ProductId: 1,
            Quantity: 1
        }
    ]

    const resultStub = {
        "Result": "Valid",
        "Number": "600007",
        "Title": "{\"fr\":\"15 dégustations\"}",
        "Products": [
            {
                "ItemName": "{\"fr\":\"10 dégustations\"}",
                "Quantity": 8,
                "Id": 11,
                "Message": "Success",
                "OriginalQuantity": 15,
                "ProductId": 0
            }
        ]
    }


    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState(resultStub)
    const [cannotValidate, setCannotValidate] = useState(true)
    const [availableProductList, setavailableProductList] = useState(availableProducts)
    const { user } = useAuth()
    const { scanMode, controlPoint, products, productList, setProductList, currentCode } = useScan()



    useEffect(() => {
        setCannotValidate(productList.every(product => product.QuantityToConsume === 0))
    }, [productList])


    const addProductQuantity = (currentProduct) => {

        setProductList(
            productList.map((product) => {
                if (currentProduct === product) {
                    //if(product === product)
                    product.QuantityToConsume = product.QuantityToConsume < product.Quantity ? product.QuantityToConsume + 1 : product.Quantity
                }
                return product
            })
        )
    }

    const removeProductQuantity = (currentProduct) => {

        setProductList(
            productList.map((product) => {
                if (currentProduct === product) {

                    product.QuantityToConsume = product.QuantityToConsume > 0 ? product.QuantityToConsume - 1 : 0
                }
                return product
            })
        )
    }

    const resetProductQuantity = () => {
        setProductList(
            productList.map((product) => {
                product.QuantityToConsume = 0
                return product
            }
            ))
    }

    const validateProduct = (code, onValidationComplete, onValidationError) => {
        setIsLoading(true)
        getAvailableProducts(user.Event.Id, code)
            .then((items) => {
                try {
                    var configuredProducts = items.filter(item => products.some(product => product.Id === item.ProductId)&& item.Quantity > 0)
                    var mappedconfiguredProducts = configuredProducts.map((d) => ({
                        ...d,
                        QuantityToConsume: d.Quantity > 0 ? 1 : 0,
                    }))
                    setProductList(mappedconfiguredProducts)
                    if (onValidationComplete) {
                        onValidationComplete(mappedconfiguredProducts)
                    }
                } catch {

                    if (onValidationError) {
                        onValidationError(items.Message)
                    }
                }
            })
        setIsLoading(false)
    }

    const consumeProducts = () => {
        setIsLoading(true)
        var consumeProductList = productList.map((product) => ({
            ProductId: product.Id,
            Quantity: product.QuantityToConsume
        }))
        postConsumeProducts(user.Event.Id, currentCode, consumeProductList)
            .then((result) => {
                setResult(result)
            })


    }

    return { validateProduct, isLoading, productList, addProductQuantity, removeProductQuantity, resetProductQuantity, consumeProducts, result, cannotValidate, availableProductList }
}