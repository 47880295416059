import { useState, useEffect } from 'react';

const useMediaStream = () => {
  const [stream, setStream] = useState(null);
  const [torchOn, setTorchOn] = useState(false);

  useEffect(() => {
    const getMedia = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment' },
        });
        setStream(mediaStream);
      } catch (err) {
        console.error('Error accessing media devices.', err);
      }
    };

    getMedia();
  }, []);

  const toggleTorch = async () => {
    if (stream) {
      const videoTrack = stream.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities();

      if (capabilities.torch) {
        const newTorchState = !torchOn;
        await videoTrack.applyConstraints({
          advanced: [{ torch: newTorchState }],
        });
        setTorchOn(newTorchState);
      } else {
        console.error('Torch is not available on this device.');
      }
    }
  };

  return { stream, torchOn, toggleTorch };
};

export default useMediaStream;