import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../api/axios";
import { ScanModes } from "../utils/scanModeEnum";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";

const ScanningContext = createContext(null)

export const ScanningProvider = ({children}) => {
    

    const [scanMode, setScanMode] = useState(localStorage.getItem("currentMode") ? parseInt(localStorage.getItem("currentMode")): 0)
    const [controlPoint, setControlPoint] = useState(JSON.parse(localStorage.getItem("controlPoints")))
    const [products, setProducts] = useState(localStorage.getItem("products") ? JSON.parse(localStorage.getItem("products")): [])
    const [isConfigured, setIsConfigured] = useState(() => localStorage.getItem("configured") === "true" ? true : false)
    const [productList, setProductList] = useState([])
    const [currentCode, setCurrentCode] = useState("")

    const scanModeTitle = scanMode.ACCESS ? "Contrôle d'accès" : "Produits"

    const resetScan = () => {
        setProductList([])
        setCurrentCode("")
    }

    const saveScanConfig = () => {

        try {
            const config = {
                mode: scanMode,
                controlPoint: controlPoint,
                products: products
            }
            localStorage.setItem("currentMode", scanMode)
            localStorage.setItem("products", JSON.stringify(products))
            localStorage.setItem("controlPoints", JSON.stringify(controlPoint))
            localStorage.setItem("configured", true)
        } catch {
            return false
        }
        setIsConfigured(true)
        return true
    }

    



    return (<ScanningContext.Provider value={{scanMode, setScanMode, controlPoint, setControlPoint, products, setProducts, isConfigured, saveScanConfig, productList, setProductList, currentCode, setCurrentCode, scanModeTitle, resetScan}}>
        {children}
    </ScanningContext.Provider>
    )
}

export const useScan = () => useContext(ScanningContext)