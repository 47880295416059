import React from 'react'
import { AppBar,Box,IconButton,Toolbar, } from '@mui/material'
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccounts'
import logoSplash from '../assets/logoSplash.png'

export const AppLayout = ({children, callback}) => {

  return (
    <>
    <AppBar position='static' color='error'><Toolbar>

        <Box sx={{ flexGrow: 1 }}>
            <Box component="img" src={logoSplash} sx={{ height: 40 }} />
        </Box>
        <IconButton onClick={() => {if(callback) {callback()}}}><ManageAccountsTwoToneIcon sx={{ color: "#ffffff" }} fontSize='large' /></IconButton>
    </Toolbar>
    </AppBar>

    <Box bgcolor="#dddddd">
        <Box maxWidth="sm" p={2} height="100vh" margin="auto">
            {children}
        </Box>
    </Box>
</>
  )
}
