import CryptoJS from 'crypto-js';

export function decryptData(encryptedData) {
    const key = CryptoJS.enc.Utf8.parse('0123456789abcdef0123456789abcdef'); // La même clé que côté serveur
    const iv = CryptoJS.enc.Utf8.parse('abcdef9876543210'); // Le même IV que côté serveur

    var decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
        keySize: 256 / 32,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8); // Déchiffrer en UTF-8
}