import React, { useEffect, useState } from 'react'
import { useProductValidation } from '../hooks/useProductValidation'
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Typography, IconButton, Box, Icon, SvgIcon, Paper } from '@mui/material'
import IconAdd from '@mui/icons-material/AddCircleOutlineTwoTone'
import IconRemove from '@mui/icons-material/RemoveCircleOutlineTwoTone'
import CheckCircleIcon from '@mui/icons-material/CheckCircleTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import { useScan } from '../context/scanContext'




const ProductTicketScanResponse = ({onCancel}) => {

    const { productList, addProductQuantity, removeProductQuantity, resetProductQuantity, consumeProducts, result, cannotValidate, validateProduct} = useProductValidation()
    const {currentCode} = useScan()
    const [openResult, setOpenResult] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        validateProduct(currentCode, () => setIsLoading(false), () => setIsLoading(false))

    }, [])


    const handleValidation = () => {
        consumeProducts()
        setOpenResult(true)
    }

    if(isLoading){
        return <p>Chargement</p>
    }

    return (
        <>
            {!openResult ?
                <>
                    {productList.length === 0 ?
                        <Grid container direction="row" alignItems="center" pb={1} justifyContent="center">
                            <ErrorTwoToneIcon fontSize='large' sx={{ color: "#ff0000" }} />
                            <Typography variant="h5" color="#ff0000" fontWeight="bold">{productList ? "Aucun produit à consommer" : "Aucun produits restants"}</Typography>
                        </Grid>
                        :
                        <Typography textAlign="center" variant='h5' pb={1}>Choisissez les produits</Typography>}
                        
                    <Grid container textAlign='center' direction='column' bgcolor="#ffffff" borderRadius={5}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Typography fontWeight='bold'>Produit</Typography></TableCell>
                                        <TableCell align='center'><Typography fontWeight='bold'>Restant</Typography></TableCell>
                                        <TableCell align='center'><Typography fontWeight='bold'>Quantité</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {productList.map((row) => (
                                        <TableRow key={row.Id}>
                                            <TableCell>{JSON.parse(row.ItemName).fr}</TableCell>
                                            <TableCell align='center'>{row.Quantity}</TableCell>
                                            <TableCell align='center'>
                                                <Grid container alignItems='center' justifyContent='center' minWidth={120}>
                                                    <Grid item xs={5} onClick={() => removeProductQuantity(row)}><IconButton><IconRemove fontSize='large' /></IconButton></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography>{row.QuantityToConsume}</Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <IconButton onClick={() => addProductQuantity(row)}><IconAdd fontSize='large' /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid item>
                            <Button color='error' onClick={() => resetProductQuantity()}>Tout retirer</Button>
                        </Grid>

                        <Grid container position='fixed' bottom='0' left='0' justifyContent='center' py={3}>
                            <Grid container justifyContent='space-between' maxWidth='sm' spacing={2} px={1}>
                                <Grid item xs={6} lg={3}>
                                    <Button fullWidth variant='contained' color='error' size='large' onClick={onCancel}>Annuler</Button>
                                </Grid>
                                <Grid item xs={6} lg={3}>
                                    <Button fullWidth disabled={cannotValidate} variant='contained' color='success' size='large' onClick={() => handleValidation()}>Valider</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                :
                <Grid container direction='column'>
                    <Grid container alignItems='center' justifyContent='center' direction='column' bgcolor="#ffffff" borderRadius={10}  >
                        <Typography variant='h1' fontWeight='bold'>{result?.Result}</Typography>

                        <CheckCircleIcon sx={{ fontSize: 200, color: "#00cc00" }} />

                        <Typography variant='h5' fontWeight='bold'>{result?.Number}</Typography>
                        <Typography variant='h5'>{JSON.parse(result?.Title).fr}</Typography>
                        <Typography pt={5}>Détails de la transaction</Typography>
                        <Grid item width='100%' px={2} pb={2}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width='80%%'><Typography fontWeight='bold'>Produit</Typography></TableCell>
                                            <TableCell align='center'><Typography fontWeight='bold'>Quantité</Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {result.Products.map((item) => {
                                            {
                                                if (item.Quantity !== 0) {
                                                    return (<TableRow>
                                                        <TableCell>{JSON.parse(item.ItemName).fr}</TableCell>
                                                        <TableCell align='center'>{productList.map((product) => {
                                                            if (product.Id === item.Id) {
                                                                return product.QuantityToConsume
                                                            }
                                                        })}</TableCell>
                                                    </TableRow>)
                                                }
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                    <Grid container position='fixed' bottom='0' left='0' justifyContent='center' p={3}>

                        <Grid item xs={12} lg={6}>
                            <Button fullWidth variant='contained' size='large' onClick={onCancel}>Retour</Button>
                        </Grid>

                    </Grid>
                </Grid>
            }
        </>
    )
}

export default ProductTicketScanResponse