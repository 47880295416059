import { AppBar, CssBaseline } from '@mui/material';
import ButtonAppBar from './components/ButtonAppBar';
import QRCodeLoginView from './views/QRCodeLoginView';
import LoginView from './views/LoginView';
import EventModeView from './views/EventModeView';
import ScanView from './views/ScanView';
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AppContainer from './components/AppContainer';
import PrivateRoute from './router/privateRoute';
import ConfiguredRoute from './router/configuredRoute';
import AccountView from './views/AccountView';
import { useEffect } from 'react';
import { HistoryView } from './views/HistoryView';

function App() {

  return (
    <CssBaseline>
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<LoginView />} />
          <Route path="qrlogin" element={<QRCodeLoginView />} />
          <Route element={<PrivateRoute />}>
            <Route path="mode" element={<EventModeView />} />
            <Route element={<ConfiguredRoute />}>
              <Route path="scan" element={<ScanView />} />
              <Route path="user-history" element={<HistoryView />} />
              <Route path="settings" element={<AccountView />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="scan" replace />} />
        </Routes>
      </BrowserRouter>
    </CssBaseline>

  );
}

export default App;
