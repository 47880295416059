import React, { useState, useEffect } from 'react'
import { Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Box, CircularProgress } from '@mui/material'
import { useTicketHistory } from '../hooks/useTicketHistory'
import { useScan } from '../context/scanContext'

const TicketHistory = ({onCancel}) => {

    const [isLoading, setIsLoading] = useState(true)
    const {currentCode, scanMode} = useScan()
    const {getTicketHistory, scanEvents} = useTicketHistory()

    useEffect(() => {
            getTicketHistory(currentCode, setIsLoading(false),setIsLoading(false) );
    },[])


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const offset = -4; // UTC-4

        const localDate = new Date(date.getTime() + offset * 60 * 60 * 1000);
        //const date = new Date(dateString);
        const day = String(localDate.getDate()).padStart(2, '0');
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const year = localDate.getFullYear();
        const hours = String(localDate.getHours()).padStart(2, '0');
        const minutes = String(localDate.getMinutes()).padStart(2, '0');
        const seconds = String(localDate.getSeconds()).padStart(2, '0');
      
        return `${day}/${month}/${year} ${hours}:${minutes}`;
      };

      if (isLoading) {
        return (
          <Box container textAlign='center' alignContent="center" height="100%" direction="column" bgcolor="#ffffff" borderRadius={5} p={2}>
            <CircularProgress />
            <Typography>Chargement en cours</Typography>
          </Box>
        )
      }

  return (
    <>
    <Typography textAlign="center" variant='h5' pb={1}>Historique des transactions</Typography>
    <Grid container textAlign='center' direction='column' bgcolor="#ffffff" borderRadius={5} style={{ overflowY: 'auto', maxHeight: '75vh' }}>
        {scanEvents.length === 0 ? <Typography>Aucun élément à afficher</Typography>:
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                    <TableCell><Typography fontWeight='bold'>Créé par</Typography></TableCell>
                    <TableCell><Typography fontWeight='bold'>Heure</Typography></TableCell>
                        <TableCell align='center'><Typography fontWeight='bold'>Action</Typography></TableCell>
                        <TableCell align='center'><Typography fontWeight='bold'>{scanMode === 1 ? "Résultat":"Produit"}</Typography></TableCell>
                        {scanMode != 1 ? <TableCell align='center'><Typography fontWeight='bold'>Quantité</Typography></TableCell> : <></>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {scanEvents.map((row) => (
                        <TableRow key={row.Id}>
                            {scanMode === 1 ? <TableCell><Typography fontSize={12}>{row.Source}</Typography></TableCell> : <></>}
                            <TableCell><Typography fontSize={12}>{formatDate(row.EventTime)}</Typography></TableCell>
                            <TableCell><Typography fontSize={12}>{row.EventType}</Typography></TableCell>
                            <TableCell><Typography fontSize={12}>{row.Description}</Typography></TableCell>
                            {scanMode != 1 ? <TableCell align='center'><Typography fontSize={12}>{row.Quantity}</Typography></TableCell> : <></>}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
}
        <Grid container position='fixed' bottom='0' left='0' justifyContent='center' py={3}>
            <Grid container justifyContent='space-between' maxWidth='sm' spacing={2} px={1}>
 
                <Grid item xs={12}>
                    <Button fullWidth variant='contained' size='large' onClick={() => onCancel()}>Retour</Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    </>
  )
}

export default TicketHistory