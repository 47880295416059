import React from 'react'
import { useAuth } from '../context/authcontext'
import { Navigate, Outlet, Route, redirect, useNavigate } from 'react-router-dom'
import { useScan } from '../context/scanContext'



const ConfiguredRoute = ({children}) => {
    const {isConfigured} = useScan()

    if(!isConfigured) {
        return <Navigate to="/mode"/>
    }

  return <Outlet/>
}

export default ConfiguredRoute