import { Box, Button, Container, FormControl, FormLabel, Input, InputLabel, TextField, Typography, Grid, AppBar } from '@mui/material'
import logo from '../assets/logo.png'
import React, { useState } from 'react'
import { Form, Navigate } from 'react-router-dom'
import { useHistory, useNavigate } from 'react-router-dom';
import ButtonAppBar from '../components/ButtonAppBar';
import { useAuth } from '../context/authcontext';

const LoginView = () => {

    const {login, qrlogin} = useAuth()
    const [username, setUsername] = useState("");
    const [error, setError] = useState();
    const navigate = useNavigate()
    

    const handleLogin = () => {
      setError(null)
      qrlogin(username,  () => navigate("/mode"), (error) => setError(error))
    }

    return (

    <Container maxWidth="sm" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box textAlign="center" style={{ width: '100%' }}>
        <img src={logo} alt="Logo" height="80" />
        <Box py={5}>

            <Grid spacing={2} alignItems="center" justifyContent="center" >

              <Box pb={2}>
                <TextField
                  fullWidth
                  label="Clé de connexion"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  
                />
                {error ? <Typography fontWeight='bold' color="#ff0000" pt={1}>L'authentification a échouée</Typography> : <></>}
                </Box>

              <Box pb={3}>
                <Button variant="contained" size='large' color="error" fullWidth onClick={() => handleLogin()}>
                  Connexion
                </Button>
              </Box>
              <Typography fontWeight='semi-bold' mb={3} >
              OU
            </Typography>
            <Box>
            <Button variant='contained' size='large' color='error' fullWidth onClick={() => navigate('/qrlogin')}>Connexion avec QR Code</Button>
            </Box>
            </Grid>
        </Box>
      </Box>
    </Container>
  )
}

export default LoginView