import { useState } from "react"
import { postAccessScan } from "../api/apiService"
import { useAuth } from "../context/authcontext"
import { useScan } from "../context/scanContext"

export const useTicketValidation = () => {
    const validationResponseStub = {
        isValid: false,
        message: "",
        ticketName: "",
        ticketNumber: ""
    }

    const [isLoading, setIsLoading] = useState(false)
    const [validationResponse, setValidationResponse] = useState(validationResponseStub)
    const { user } = useAuth()
    const { scanMode, controlPoint, products } = useScan()

    const validateTicket = (code, onValidationComplete) => {
        setIsLoading(true)
        postAccessScan(user.Event.Id, controlPoint.Id, code)
            .then((items) => {
                var response = {
                    isValid: items.Result === "Valid" ? true : false,
                    message: items.Title,
                    ticketNumber: items.Number ? items.Number : "Inconnu",
                    ticketName: items.TicketName ? JSON.parse(items.TicketName).fr : "Inconnu"
                }
                setValidationResponse(response)
                if (onValidationComplete) {
                    onValidationComplete()
                }
            })
        setIsLoading(false)
    }

    return { validateTicket, isLoading, validationResponse }
}