import { useTorchLight } from '@blackbox-vision/use-torch-light'
import { IconButton } from '@mui/material'
import React from 'react'
import { useState, useRef } from 'react';
import useMediaStream from '../hooks/useMediaStream';
import FlashLightOffIcon from '@mui/icons-material/FlashlightOffTwoTone'
import FlashLightOnIcon from '@mui/icons-material/FlashlightOnTwoTone'

const TorchButton = () => {

  const {torchOn, toggleTorch} = useMediaStream()

  
  return (
    <IconButton onClick={toggleTorch}>{torchOn ? <FlashLightOnIcon fontSize='large' /> : <FlashLightOffIcon fontSize='large' />}</IconButton>
  )
}

export default TorchButton