import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Button, AppBar, Toolbar, IconButton } from '@mui/material'
import { useTicketValidation } from '../hooks/useTicketValidation'
import { useScan } from '../context/scanContext'
import QRCodeScanner from '../components/QRCodeScanner'
import ReverseCameraIcon from '@mui/icons-material/CameraswitchTwoTone'
import HistoryLogo from '@mui/icons-material/HistoryTwoTone'
import DocumentScannerIcon from '@mui/icons-material/DocumentScannerTwoTone';

import { ScanModes } from '../utils/scanModeEnum'
import { useProductValidation } from '../hooks/useProductValidation'

import logoSplash from '../assets/logoSplash.png'
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccounts';

import TorchButton from '../components/TorchButton'
import { useTicketHistory } from '../hooks/useTicketHistory'
import ScanResponse from './ScanResponseView'

const ScanView = () => {
  const { validateTicket, validationResponse } = useTicketValidation()
  const { validateProduct, consumeProducts } = useProductValidation()
  const { getTicketHistory, scanEvents } = useTicketHistory()
  const { scanMode, setCurrentCode, resetScan, controlPoint} = useScan()
  const [openResponse, setOpenResponse] = useState(false)
  const [result, setResult] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [historyMode, setHistoryMode] = useState(false)



  const handleScan = (text) => {

    // setIsLoading(true)
    // setCurrentCode(text)
    setCurrentCode(text)
    setOpenResponse(true)

    // if (historyMode) {
    //   getTicketHistory(text, () => {
    //     setOpenResponse(true)
    //   }, (message) => {
    //     setError(message)
    //     setOpenResponse(true)
    //   })
    //   return;
    // }

    // if (scanMode === ScanModes.ACCESS) {
    //   if (controlPoint.HasProductsToValidate)
    //   {
    //     validateProduct(text, () => {
    //       setOpenResponse(true)
    //     })
    //   }
    //   validateTicket(text, () => {
    //     setOpenResponse(true)
    //   })
    // }

    // if (scanMode === ScanModes.PRODUCT) {
    //   validateProduct(text, () => {
    //     setOpenResponse(true)
    //   }, (message) => {
    //     setError(message)
    //     setOpenResponse(true)
    //   })
    // }
  }

  const handleCancel = () => {
    setOpenResponse(false)
    setError(null)
    resetScan()
    setHistoryMode(false)
  }

  const toggleHistoryMode = () => {
    setHistoryMode(!historyMode)
  }

  return (

    <>
    <Box bgcolor="#dddddd" height="100vh">
      <AppBar position='static' color='error'>
        <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Box component="img" src={logoSplash} sx={{ height: 40 }} />
        </Box>
        <IconButton href='/settings'><ManageAccountsTwoToneIcon sx={{ color: "#ffffff" }} fontSize='large' /></IconButton>
      </Toolbar>
      </AppBar>
        <Box maxWidth="sm" p={2} margin="auto">
          {openResponse ?
            <ScanResponse
              onCancel={handleCancel}
              isHistoryValidation={historyMode}
            />
            :
            <Box>
              <Typography textAlign="center" variant='h5' pb={2}>Balayez le billet</Typography>
              <QRCodeScanner onScan={(result) => handleScan(result)} isHistory={historyMode}/>
              <Grid container direction='row' justifyContent='space-between' p={2}>
                <TorchButton />
                <IconButton><ReverseCameraIcon fontSize='large'/></IconButton>
              </Grid>
              <Grid container justifyContent="center">
                <Button variant='contained' size='large' startIcon={!historyMode ? <HistoryLogo /> : <DocumentScannerIcon/>} onClick={toggleHistoryMode}>{!historyMode ? "Consulter l'historique" : "Retour au mode normal"}</Button>
              </Grid>
            </Box>
          }
          <Typography>{result}</Typography>
        </Box>
      </Box>
    </>
  )
}

export default ScanView