import axios from "axios";

const axiosPrivate = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

axiosPrivate.interceptors.request.use(
    config => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.ApiKey) {
            config.headers['api-key'] = user.ApiKey;
        }
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            config.headers['timezone'] = timezone
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

  axiosPrivate.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
      }
      return Promise.reject(error);
    }
  );

export const getEventModes = (eventId) =>
    axiosPrivate.get("/scanapp/event/eventmodes", {
        params: {eventId},
    }).then((response) => response.data);

export const getControlPoints = (eventId) =>
    axiosPrivate.get("/scanapp/event/controlpoints", {
        params: {eventId},
    }).then((response) => response.data);

export const postAccessScan = async (eventId, controlPointId, code) => {
    const formData = new FormData()
    formData.append('eventId', eventId)
    formData.append('controlPointId', controlPointId)
    formData.append('code', code)

    const response = await axiosPrivate.post("/scanapp/scan/validate", formData);
    return response.data;
}

export const getAvailableProducts = async (eventId, code) => {

    const response = await axiosPrivate.get("/scanapp/scan/product", { params: {eventId,code}})
    return response.data
}

export const postConsumeProducts = async (eventId, code, consumeDto) => {
    var params = {
        EventId: eventId,
        ProductQuantities: consumeDto,
        Code: code.text
    }

    const response = await axiosPrivate.post("/scanapp/product/consume", {
        ...params
      })

    return response.data
}

export const getTicketScanEvents = async (eventId, ticketId) => {

    const response = await axiosPrivate.get("/scanevent/code/" + ticketId, { params: {eventId}})

    return response.data
}