import React from 'react'
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Typography, IconButton, Box, Icon, SvgIcon, Paper } from '@mui/material'
import IconAdd from '@mui/icons-material/AddCircleOutlineTwoTone'
import IconRemove from '@mui/icons-material/RemoveCircleOutlineTwoTone'
import CheckCircleIcon from '@mui/icons-material/CheckCircleTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import { useProductValidation } from '../hooks/useProductValidation';

const ProductsSelectionTable = () => {
    const { productList, addProductQuantity, removeProductQuantity, resetProductQuantity, consumeProducts, result, cannotValidate } = useProductValidation()

  return (
    //<Grid container textAlign='center' direction='column' bgcolor="#ffffff" borderRadius={5}>
    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Typography fontWeight='bold'>Produit</Typography></TableCell>
                                        <TableCell align='center'><Typography fontWeight='bold'>Restant</Typography></TableCell>
                                        <TableCell align='center'><Typography fontWeight='bold'>Quantité</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {productList.map((row) => (
                                        <TableRow key={row.Id}>
                                            <TableCell>{JSON.parse(row.ItemName).fr}</TableCell>
                                            <TableCell align='center'>{row.Quantity}</TableCell>
                                            <TableCell align='center'>
                                                <Grid container alignItems='center' justifyContent='center' minWidth={120}>
                                                    <Grid item xs={5} onClick={() => removeProductQuantity(row)}><IconButton><IconRemove fontSize='large' /></IconButton></Grid>
                                                    <Grid item xs={2}>
                                                        <Typography>{row.QuantityToConsume}</Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <IconButton onClick={() => addProductQuantity(row)}><IconAdd fontSize='large' /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid item>
                            <Button color='error' onClick={() => resetProductQuantity()}>Tout retirer</Button>
                        </Grid>
                        </>
                        //</Grid>
  )
}

export default ProductsSelectionTable