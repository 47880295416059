import React from 'react'
import { AppBar,Toolbar, Box,IconButton, Grid, Typography, Avatar, Button } from '@mui/material'
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccounts'
import logoSplash from '../assets/logoSplash.png'
import { useAuth } from '../context/authcontext'
import BackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'


const AccountView = () => {


    const navigate = useNavigate()
    const {user, logout} = useAuth()

    const isAdmin = () => {
        if(user.Admin == "true")
            {
                return true
            }
            return false
    }

    return (
        <>
            <AppBar position='static' color='error'><Toolbar>
                <Box sx={{ flexGrow: 1 }}>
                    <Box component="img" src={logoSplash} sx={{ height: 40 }} />
                </Box>
                <IconButton onClick={() => navigate('scan')}><BackIcon sx={{ color: "#ffffff" }} fontSize='large' /></IconButton>
            </Toolbar>
            </AppBar>
            <Box bgcolor="#dddddd">
            <Box maxWidth="sm" height='100vh' margin='auto' p={2}>
            <Typography textAlign='center' variant='h4' pb={2}>Réglages</Typography>
                    <Grid container  bgcolor="#ffffff" borderRadius={10} p={2}>
                        <Grid container direction='column' alignItems='center' justifyContent='center' spacing={10}>
                        <Grid item>
                            <Avatar></Avatar>
                            <Typography>{user.FirstName}</Typography>
                            <Typography>{user.LastName}</Typography>
                        </Grid>
                        <Grid item>
                            {/* <Grid container direction='column' spacing={1}>
                                <Grid item> */}
                                <Box pb={2}>
                                    <Button fullWidth variant='contained' size='large' href='/mode' disabled={!user.Admin}>Changer le mode</Button>
                                    </Box>
                                {/* </Grid>
                                <Grid item> */}
                                
                                    <Button fullWidth variant='contained' size='large' color='error' onClick={logout}>Déconnexion</Button>
                                {/* </Grid>
                            </Grid> */}
                        </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default AccountView