import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../api/axios";



const AuthContext = createContext(null)


export const AuthProvider = ({children}) => {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))
    const [isAuthenticated, setIsAthenticated] = useState(user ? true : false)

    const login = async (apiKey) => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/scanapp/qrlogin', new URLSearchParams({
                apiKey,
            }))
            setUser(response.data.User)
            localStorage.setItem('user', JSON.stringify(response.data.User));
        } catch (error) {
            console.error('Error logging in')
        }
    }

    const qrlogin = async (loginKey, onValid, onError) => {
        const formData = new FormData()
        formData.append('Code', loginKey)
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/scanapp/qrlogin', formData)
            setUser(response.data.User)
            localStorage.setItem('user', JSON.stringify(response.data.User));
            onValid()
            
        } catch (error) {
            onError(error?.response?.data?.Message)
        }
    }

    const logout = () => {
        localStorage.clear()
        setUser(null)
    }

    return (
        <AuthContext.Provider value={{user, isAuthenticated, setUser, login, qrlogin, logout}}>
            {children}
        </AuthContext.Provider>
    )

}

export const useAuth = () => useContext(AuthContext)