import axios from 'axios';
import { useAuth } from '../context/authcontext';

const axiosPrivate = axios.create({
 baseURL: "https://api.ticketngov2.com",
  headers: {
    'Api-Key': '77d5cde8-cd2c-4f0a-af79-93daee7092c2'
  }
});

const useAxiosPrivate = () => {
  const {user, logout} = useAuth()

  //if (user && user.ApiKey) {
   return axiosPrivate.defaults.headers.common['ApiKey'] = '77d5cde8-cd2c-4f0a-af79-93daee7092c2';
  //}
  

  // axiosPrivate.interceptors.response.use(
  //   response => response,
  //   error => {
  //     if (error.response && error.response.status === 401) {
  //       logout()
  //       // Place your reentry code here
  //       localStorage.clear();
  //     }
  //     return Promise.reject(error);
  //   }
  // );

  return axiosPrivate;
};

export default useAxiosPrivate;