import { Box, Container, Grid, Typography, Button } from '@mui/material'
import React, { useState } from 'react'
import target from '../assets/scannerOverlay.png'
import ButtonAppBar from '../components/ButtonAppBar';
import { useAuth } from '../context/authcontext';
import { decryptData } from '../services/encryptionService';
import { useNavigate } from 'react-router-dom';
import { useZxing } from 'react-zxing';
import QRCodeScanner from '../components/QRCodeScanner';
import { AppLayout } from '../components/AppLayout';
import TorchButton from '../components/TorchButton';

const QrCodeLoginView = () => {
    const {qrlogin} = useAuth()
    const navigate = useNavigate()
    const [text, setText] = useState()
    const [error, setError] = useState()

    const handleScan = (result) => {
        console.log(result.text)
        var apiKey = decryptData(result.text)
        qrlogin(apiKey,  () => navigate("/mode"), (error) => setError(error))
    }


    return (

        <>
            <AppLayout>
                <Box>
                    <Typography textAlign='center' variant='h5'>Balayez le code QR d'authentification</Typography>
                    <QRCodeScanner onScan={(result) => handleScan(result)} />

                    <p>{error}</p>
                    <TorchButton/>
                </Box>
            </AppLayout>
            <Grid container position='fixed' bottom='0' left='0' justifyContent='center' p={3}>

                <Grid item xs={12}>
                    <Button fullWidth variant='contained' size='large' onClick={() => navigate('/login')}>Retour</Button>
                </Grid>

            </Grid>
        </>
    );
};


export default QrCodeLoginView