import { Box, Button, keyframes } from '@mui/material';
import { styled } from '@mui/system';
import { useZxing } from "react-zxing"
import scannerOverlay from "../assets/scannerOverlay.png"
import React, { useEffect, useRef, useState } from 'react'
import useMediaStream from '../hooks/useMediaStream';
import HistoryLogo from '@mui/icons-material/HistoryTwoTone'
import { useScan } from '../context/scanContext';

const QRCodeScanner = ({ onScan, isHistory }) => {
  const fadeInOut = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

  const Fade = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    animation: `${fadeInOut} 2s infinite`,
  });

  const videoRef = useRef(null);
  const { stream } = useMediaStream()

  const { ref } = useZxing({
    onDecodeResult(result) {
      onScan(result)
    },
  });


  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    }
     if (stream && ref.current) {
       ref.current.srcObject = stream;
     }
  }, [stream, ref]);

  return (
    <>
      <Box position="relative" width="100%" height="60vh" overflow="hidden" margin="auto" borderRadius="10px">
        <video ref={ref} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: 'cover' }} />
        <img src={scannerOverlay} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: 'cover' }} />
        {isHistory ?
        <Fade>
          <Box>
            <HistoryLogo color='primary' sx={{ fontSize: 300 }} />
          </Box>
        </Fade>
        : <></>}
      </Box>
    </>
  )
}

export default QRCodeScanner