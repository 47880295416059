import { Container } from '@mui/material'
import React from 'react'

const AppContainer = ({children}) => {
  return (
    <Container maxWidth="sm" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {children}
    </Container>
  )
}

export default AppContainer