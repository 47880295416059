import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { blue, red } from '@mui/material/colors';

const SquareRadioButtons = (props) => {
  const [selected, setSelected] = useState(props.value);

  const handleClick = (value) => {
    setSelected(value)
    props.onChange(value)
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems:'center', gap: '16px'}}>
      <Button
        variant={selected === props.valueLeft ? 'contained' : 'outlined'}
        onClick={() => handleClick(props.valueLeft)}
        sx={{
          width: '50%',
          height: 100,
          backgroundColor: selected === props.valueLeft ? red[500] : 'transparent',
          borderColor: red[500],
          '&:hover': {
            backgroundColor: selected === props.valueLeft ? red[700] : red[50],
          },
          color: selected === props.valueLeft ? '#fff' : red[500],
        }}
      >
        {props.valueLeftLabel}
      </Button>
      <Button
        variant={selected === props.valueRight ? 'contained' : 'outlined'}
        onClick={() => handleClick(props.valueRight)}
        sx={{
          width: '50%',
          height: 100,
          backgroundColor: selected === props.valueRight ? red[500] : 'transparent',
          borderColor: red[500],
          '&:hover': {
            backgroundColor: selected === props.valueRight ? red[700] : red[50],
          },
          color: selected === props.valueRight ? '#fff' : red[500],
        }}
      >
        {props.valueRightLabel}
      </Button>
    </div>
  );
};

export default SquareRadioButtons;