import { Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box } from '@mui/material'
import React from 'react'
import { AppLayout } from '../components/AppLayout'

export const HistoryView = () => {

    return (
        <AppLayout>
            <Typography textAlign='center' variant='h5' fontWeight='semi-bold' pb={1}>Historique de l'utilisateur</Typography>
            <Grid container bgcolor='#ffffff' justifyContent='center' borderRadius={10} p={2}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography fontWeight='semi-bold'>Billet</Typography></TableCell>
                                <TableCell><Typography fontWeight='semi-bold'>Résultat</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Box>
                                        <Typography>Admission générale</Typography>
                                        <Typography>4540</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box>
                                        <Typography>INVALIDE</Typography>
                                        <Typography>Le billet est déjà sur le site</Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </AppLayout>
    )
}
